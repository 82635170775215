.AccountList-add-account {
  margin: 10px;
}

.AccountList-card-group {
  display: flex;
  flex-wrap: wrap;
}

.AccountList-card {
  margin: 10px;

}

.AccountList-sync-loading {
  display: flex;
  border: 1px solid #045681;
  border-radius: 5px;
  margin: 10px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  height: 3em;
}