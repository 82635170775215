nav {
  justify-content: center;
}

nav a {
  text-decoration: none;
  color: white;
}

nav a.active {
  text-decoration: underline;
}

